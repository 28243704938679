import ReactDOM from 'react-dom/client';

import './i18n/i18next';
import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import { Suspense, lazy } from 'react';
const App = lazy(() => import('./App'));

// if (import.meta.env.VITE_REACT_APP_ENV !== 'development') {
//   Sentry.init({
//     release: '3.0.6',
//     dsn: '',
//     environment: import.meta.env.VITE_REACT_APP_ENV,
//     integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//     beforeSend(event, hint) {
//       const error = hint?.originalException as AxiosError;
//       if (error) {
//         return null;
//       } else {
//         return event;
//       }
//     },
//     tracesSampleRate: 0.2,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }

const ContainerApp = () => {
  return (
    <>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </>
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<ContainerApp />);
